import React, {Component} from 'react'

import projectList from '../json/projects.json'
import SlideShow from './SlideShow'

class ProjectList extends Component{
    

    render(){
        let projects = Object.keys(projectList.projects)
        console.log(projects)
        return(
            projects.map((project)=>(
                <div className = 'Project'>
                    <div className = 'content'>
                        {projectList.projects[project].link != null ?
                        (<a href={projectList.projects[project].link}><h2>{project}</h2></a>): 
                        (<h2>{project}</h2>)}
                        {projectList.projects[project].points != null ?(
                            <ul>
                                {projectList.projects[project].points.map(point=>(<li>{point}</li>))}
                            </ul>
                            ):(null)}
                        <div className = 'contentBox'>
                            <p>{projectList.projects[project].description}</p>

                            <div className = 'oneLine Table'>
                                <div className = 'column'>
                                Technologies
                                    <ul>
                                    {
                                        projectList.projects[project].technologies.map(technology=>
                                            (
                                                <li>{technology}</li>
                                            ))
                                    }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    {projectList.projects[project].images != null? (
                        <SlideShow images = {projectList.projects[project].images}/>
                    ):
                    (null)}
                    

                    
                </div>
            ))
        )
    };
}

export default ProjectList
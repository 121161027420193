import React, {Component} from 'react'
import {AnimateOnChange} from 'react-animation'
import 'react-animation/dist/keyframes.css'
import './SlideShow.scss'

class SlideShow extends Component{

    constructor(props){
        super(props)

        var x;

        console.log(this.props.images)

        this.state = {
            currentImage: 0,
            size: this.props.images.size,
        }

        this.changeImage = this.changeImage.bind(this)
        this.changeImageManual = this.changeImageManual.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.createButtons = this.createButtons.bind(this)

        if (this.props.images.size > 1 ) { this.x = setInterval(this.changeImage, 5000)}
    }

    changeImage(moveStep){

        if(moveStep == null){
            if(this.state.currentImage === this.props.images.size-1){
                this.setState({currentImage: 0})
            }
            else{
                this.setState({currentImage: this.state.currentImage+1})
            }
        }
        else{
            if (moveStep < 0){
                if(this.state.currentImage == 0){
                    this.setState({currentImage: this.props.images.size-1})
                }
                else{
                    this.setState({currentImage: this.state.currentImage+moveStep})
                }
            }
            else if (moveStep > 0){
                if(this.state.currentImage === this.props.images.size-1){
                    this.setState({currentImage: 0})
                }
                else{
                    this.setState({currentImage: this.state.currentImage+moveStep})
                }
            }
        }
        
    }

    changeImageManual(event){

        if(this.x != null){
            clearInterval(this.x)
        }

        if(event.ButtonPress === 'forward'){
            this.changeImage(1)
        }
        else if(event.ButtonPress === 'backward'){
            this.changeImage(-1);
        }
    }

    handleButtonClick(event){
        if(this.x != null){
            clearInterval(this.x)
        }

        this.changeImage(event.target.id - this.state.currentImage)

    }

    createButtons(){
        var buttons = []
        for(var i = 0; i < this.props.images.size; i++){
            buttons.push(<div className = 'button' id={i} onClick={this.handleButtonClick} style={
                i == this.state.currentImage? {backgroundColor:'#00FDDC', opacity: 1}: null}></div>)
        }
        return buttons
    }

    render(){
        return(
            this.state.size > 1 ? (
                this.props.images != null ? (
                    <div style = {{width: 'fit-content'}}>
                        <div className = 'SlideShow'>
                            <AnimateOnChange animationOut='slideAnimation-out 250ms linear forwards' animationIn = 'slideAnimation-in 250ms linear forwards' durationOut = {250} durationIn={500}>
                                <img className = 'SlideShowImage' src = {this.props.images.location + '/' + this.state.currentImage.toString() + '.png'} alt = 'null'/>
                            </AnimateOnChange>

                            <div className = 'buttonBox'>
                                {this.createButtons()}
                            </div>

                            <div name = 'backwards'className = 'back traverse' onClick = {()=>this.changeImageManual({ButtonPress: 'backward'})}></div>
                            <div name = 'forwards' className = 'forward traverse' onClick = {()=>this.changeImageManual({ButtonPress: 'forward'})}></div>
                        </div>
                        <div className = 'description'>
                            {this.props.images.description[this.state.currentImage+'.png']}
                        </div>
                    </div>
                    
                    
                    
                
                ): null 
            ):(
                this.props.images != null ? (<img className = 'SlideShow' src = {this.props.images + '/' + this.state.currentImage.toString() + '.png'} alt = 'null'></img>): null 
            )
            
        )
    }
}

export default SlideShow
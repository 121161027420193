import React from 'react'
import skills from '../json/skills.json'

function Table(){
    return(
        <div className='Table'>
            {skills.map(skill=>(
                <div className = 'column'>
                    <div className = 'columnTitle'>
                        {Object.keys(skill)}
                    </div>
                    
                    {skill[Object.keys(skill)].map(
                        item=>(
                            <li>{item}</li>
                        )
                    )}
                    
                </div>
            ))}
        </div>
    )
}

export default Table
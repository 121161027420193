import React from 'react';
import Header from './components/Header'
import Body from './components/Body'
import Footer from './components/Footer'
import Reflection1 from './components/Reflection1'
import Reflection2 from './components/Reflection2'
import './App.scss';

import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path = '/'>
          <Header></Header>
          <Body></Body>
          <Footer></Footer>
          </Route>
          <Route exact path = '/reflection/1'>
            <Reflection1 />
          </Route>
          <Route exact path = '/reflection/2'>
            <Reflection2 />
          </Route>
        </Switch>
      </Router>
      
    </div>
  );
}

export default App;

import React from 'react'

import Table from './Table'
import ProjectList from './projectList'
import Contact from './Contact'

function Body(){
    return(
        <div className = 'Body'>
            <div className = 'section'>
                <h1>About me</h1>
                <div className='info'>
                    <p>
                        Nothing is impossible. Four years ago terminal commands scared me, I didn't see a point in trying new languages, and I was extremely naive to what I could do. Today I strive to push myself in learning something new every time I work on a new project. Whether it's good or bad there's always something to take away. It's been four years of pushing myself in everything I do.
                    </p>
                    <ul>
                        <li>pursuing</li>
                        <ul>
                            <li>Computer Science BS at Washington State University expected 2021</li>
                            <li>Software Engineering BS at Washington State University expected 2021</li>
                        </ul>
                        <li>GPA: 3.6</li>
                    </ul>
                </div>
            </div>
            <div className='section'>
                <h1>Skills</h1>
                <Table/>
            </div>
            <div className = 'section'>
                <h1>Projects</h1> 
                <ProjectList/>
            </div>
            <div className = 'section'>
                <Contact/>
            </div>
        </div>
    )
}

export default Body;
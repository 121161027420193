import React, { Component} from 'react';

export class Reflection2 extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        return (
            <div className={'Reflection'}>
                <h1>Reflection 2</h1>
                <p className = {'info'}>
      Imagine living in a world where you had to struggle to do the same things everyone around you is doing, although they look simple, for you they are nothing of the sort. Many people around the world might need some kind of assistance, but many would never know it just at a glance. A couple examples are people who need glasses or those that are color blind. Nearly 75 percent of adults world need something to correct their vision and between 7 to 10 percent of men are red-green color blind. Many games created cater to these two disabilities because so many people suffer from them. Games like League of Legends or Borderlands have settings that can adjust colors and font sizes for people. Many other disabilities though may not have the level of support.<br/>
In my image I’ve chosen to link accessibility and gaming. The controller design was influenced by Xbox’s adaptive controller. The first half of the image is meant to blend nearly everything together. This is indicative of a website that uses colors to similar to each other such that it’s hard to make out details. The buttons you see originally are the small buttons that someone with a lack of fine motor skills might not be able to press. <br/>
In the second half of the image. I have shown a more accessible version. I have increased the contrast between the controller and the background. The other thing it shows is the buttons enlarged and even a few off the controller. This is indicative of a customizable controller where someone can set something up as they need it. <br/>
Besides being a link between gaming and digital accessibility, it makes an effort to link all accessibility can be digital accessibility. From how software is designed to the tools we use to input information into the system. Some people may need a different tool and it can happen at any part of the system. This image is trying to enforce that all accessibility is equally important as to no alienate any part of the community. <br/>
The price tags on both controllers could link the digital divide between rural areas and more urbanized areas. The rural areas are where people generally make less, although even in urbanized environments there are still people struggling to get by. The first controller has a very high price tag, but mediocre design. This would only allow people with money to play games. The second controller though is much cheaper and more affordable. This controller would bring more people into the hobby due to the smaller price tag. This would be financial accessibility and the same could be said for all technology. If the barrier of entry is lower then more people could reap the benefits of it. <br/>

                </p>
                <img  className = {'image'} src={'/Images/DTCReflection2.png'}/>
                <h1>Works cited</h1>
                
                <a className = {'link'} href = 'https://www.colour-blindness.com/general/prevalence/'>Color Blindness by Inheritance</a>
                <a className = {'link'} href = 'https://www.reference.com/world-view/many-people-world-wear-glasses-e1268cfa00bdbd41'>How many People in the World Wear Glasses</a>
                <a className = {'link'} href = 'https://www.xbox.com/en-US/accessories/controllers/xbox-adaptive-controller'>Xbox Adaptive Controller</a>
                <a className = {'link'} href = 'https://www.canva.com/'>To make the Image I used free assets from Cavana</a>
            </div>
        )
    }

}

export default Reflection2
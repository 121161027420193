import React, { Component} from 'react';

export class Reflection1 extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        return (
            <div className={'Reflection'}>
                <h1>Reflection</h1>
                <p className = {'info'}>
      I wanted to look at rural America and digital colonialism. As much of the United States has some form of internet it’s been much more lucrative for companies to begin investing in other countries where they can get more users to pay for their services even if the services aren’t great. Much of rural America uses DSL which has a maximum speed of 35 megabits per second. Companies make no effort to innovate and update the infrastructure of rural America.<br/>
      This major lack of updated infrastructure leaves many lower income families struggling. People who are forced to use this slower tier internet are forced to be consumers rather than producers. In other words, they can’t use the internet to create content efficiently. Someone who had a 100 megabit per second connection could download stuff 10 times faster than someone locks to 10Mbps. This doesn’t seem like a big deal, but for a file that could exceed 10s of gigabytes this could be work days of time being saved. This same issue occurs with people locked to Facebooks Free Basics internet. Where they're limited in what they can and can't do. This leaves them being only able to consume over produce. If someone could produce this could lead to a new influx of software engineers who could tackle more problems in that area rather than someone from the United States trying to solve something they don't have first hand experience with.<br/>
      I wanted to show that what ISPs are doing globally and in Rural America is not verry different. I took a simplistic approach that uses icons people may have seen. These come straight from Googles Material Icons. They bring the more techy and simple nature modern day technology. I then use the tractor or ‘agriculture’ to represent rural America. While use a bicycle(‘peddle bike’) and moped to represent other possibly less developed areas. This is due to mopeds being a very common transportation method simply as they’re cheap. They all have a low bandwidth warning above them. I ended up using this as much of rural America and countries that use stuff like Facebook Basics with a limited connection. Thus what could be considered low bandwidth. Their goal is to get people to rely on their services so they can control the cost of internet and milk the most money they can. This is why all arrows in the image lead from internet to money. The possible lack of services allows companies to profit on the lowest tier of service they could provide. This is more lucrative as some areas they enter have much less regulation and could allow them to become monopolies. <br/>
                </p>
                <img  className = {'image'} src={'/Images/DTC2.png'}/>
                <h1>Works cited</h1>
                
                <a className = {'link'} href = 'https://www.reviews.org/internet-service/how-much-is-internet/'>How Much Is Internet?</a>
                <a className = {'link'} href = 'https://broadbandnow.com/guides/dsl-vs-cable-vs-fiber#:~:text=DSL%20speed%3A%20DSL%20internet%20speeds%20are%20the%20slowest,can%20reach%20speeds%20400%20times%20faster%20than%20DSL.'>DSL vs Cable vs Fiber: Comparing Internet Options</a>
                <a className = {'link'} href = 'https://developers.facebook.com/docs/internet-org/faq#what'>Free Basics FAQ</a>
                <a className = {'link'} href = 'https://developers.facebook.com/docs/internet-org/platform-technical-guidelines'>Facebook Free Basics Technical Guidelines</a>
                <a className = {'link'} href = 'https://developers.facebook.com/docs/internet-org/participation-guidelines'>Facebook Free Basics Participation Guidelines</a>

                <a className = {'link'} href = 'https://material.io/design'>all icons</a>
                <a className = {'link'} href = 'http://rlv.zcache.com/caution_low_bandwidth_postcard-r5ddf71d7166c4d9392ee56ffb145fd5d_vgbaq_8byvr_324.jpg'>caution low bandwidth image</a>
            </div>
        )
    }

}

export default Reflection1
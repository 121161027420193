import React, {Component} from 'react'
import VisSensor from 'react-visibility-sensor'

class Contact extends Component{

    constructor(props){
        super(props)
        this.state={
            visible: false
        }

        this.wrapperRef = React.createRef()
    }

    render(){
        return(
            <VisSensor partialVisibility onChange={(isVisible)=>{(this.state.visible || isVisible)?this.setState({visible:isVisible}):this.setState({visible:false})}}>
                <div ref = {this.wrapperRef} className = {'Contact' + (this.state.visible? ' animated':' disabled')}>
                    <div style = {this.state.visible ? {transform: 'translate(0px, 0px)'}: {}}className = 'textBox'>
                        If you need a resume or unoffical transcript please contact me
                    </div>
                    
                    <a href='mailto:ponce800@hotmail.com'><button style = {this.state.visible ? {transform: 'translate(0px, 0px)'}: {}}>Email</button></a>
                    
                    
                </div>
            </VisSensor>
            
        )
    }

}

export default Contact
import React from 'react'

function Footer(){
    return(
        <div className = 'Footer bar'>
            <p>Designed by James Ponce</p>
        </div>
    )
}

export default Footer;
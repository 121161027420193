import React from 'react'

function Header(){
    return(
        <div className = 'bar'>
            <div className = 'title'>
                James Ponce
            </div>
            <ul className='information'>
                <li>{'email: '}
                    <a href='mailto:ponce800@hotmail.com'>ponce800@hotmail.com</a>
                </li>
                <li>{'phone: '}  
                    <a href='tel: +15098314868'>509-831-4868</a>
                </li>
            </ul>
        </div>
    )
}

export default Header